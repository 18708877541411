import { RightOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import AddAreaForm from "./form";
import AddIssueForm from "./form";

const AddIssue = () => {
  let navigate = useNavigate();

  return (
    <div>
      {/* Header Topbar Start */}
      <div className="p-4 bg-white border-b h-[60px] flex justify-between items-center">
        <div className="">
          <span
            className="text-gray-500 cursor-pointer"
            onClick={() => navigate("/app/projects")}
          >
            Projects
          </span>
          <span className="px-1">
            <RightOutlined className="text-xs text-gray-500" />
          </span>
          <span
            className="text-gray-500 cursor-pointer"
            onClick={() => navigate("/app/projects/view")}
          >
            View
          </span>
          {/* <span className="px-1">
            <RightOutlined className="text-xs text-gray-500" />
          </span>
          <span
            className="text-gray-500 cursor-pointer"
            onClick={() => navigate("/app/projects/area/view")}
          >
            Area
          </span>
          <span className="px-1">
            <RightOutlined className="text-xs text-gray-500" />
          </span>
          <span
            className="text-gray-500 cursor-pointer"
            onClick={() => navigate("/app/projects/area/task/view")}
          >
            Tasks
          </span> */}
          <span className="px-1">
            <RightOutlined className="text-xs text-gray-500" />
          </span>
          <span className="">Issues</span>
        </div>
      </div>
      {/* Header Topbar End*/}
      <AddIssueForm />
    </div>
  );
};

export default AddIssue;
