import React from "react";
import ProfileButton from "../Buttons/profile";
import LogoutButton from "../Buttons/logout";
import DashboardIcon from "../../Assets/Svg/dashboard";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectIcon from "../../Assets/Svg/project";
import InventoryIcon from "../../Assets/Svg/inventory";
import PurchaseIcon from "../../Assets/Svg/purchase";
import CustomerIcon from "../../Assets/Svg/customer";
import SupplierIcon from "../../Assets/Svg/suppliers";
import ContractorIcon from "../../Assets/Svg/cotractor";
import SettingIcon from "../../Assets/Svg/setting";
import ReportIcon from "../../Assets/Svg/reports";
import EmployeeIcon from "../../Assets/Svg/employee";
import SalesIcon from "../../Assets/Svg/sales";
import QuoteIcon from "../../Assets/Svg/quote";
import LogoutIcon from "../../Assets/Svg/logout";

const Sidebar = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const data = [
    {
      icon: <DashboardIcon />,
      name: "Dashboard",
      path: "/app/dashboard",
    },
    {
      icon: <ProjectIcon />,
      name: "Projects",
      path: "/app/projects",
    },
    {
      icon: <InventoryIcon />,
      name: "Items",
      path: "/app/items",
    },
    {
      icon: <QuoteIcon />,
      name: "Quotes",
      path: "/app/quotes",
    },
    {
      icon: <SalesIcon />,
      name: "Invoices",
      path: "/app/sales",
    },
    {
      icon: <PurchaseIcon />,
      name: "Purchases",
      path: "/app/purchases",
    },
    {
      icon: <EmployeeIcon />,
      name: "Employees",
      path: "/app/employees",
    },
    {
      icon: <CustomerIcon />,
      name: "Clients",
      path: "/app/customers",
    },
    {
      icon: <SupplierIcon />,
      name: "Suppliers",
      path: "/app/suppliers",
    },
    {
      icon: <ContractorIcon />,
      name: "Sub contractors",
      path: "/app/contractors",
    },
    {
      icon: <ReportIcon />,
      name: "Reports",
      path: "/app/reports",
    },
    {
      icon: <SettingIcon />,
      name: "Settings",
      path: "/app/settings",
    },
  ];

  return (
    <div className="border-r bg-white fixed left-0 top-0 bottom-0 z-50 w-64">
      {/* <div className="p-4 overflow-y-auto custom-scrollbar h-full"> */}
      <div className="p-4  custom-scrollbar h-[100%]">
        <h2 className="text-lg  mb-4">Sidebar</h2>
        <div className="pt-4">
          {data?.map((value, index) => {
            return (
              <div
                className={
                  location?.pathname?.includes(value?.path)
                    ? "my-2 cursor-pointer bg-[#2D53DA] rounded-lg"
                    : "my-2 cursor-pointer"
                }
                onClick={() => navigate(value?.path)}
              >
                <div
                  className={
                    location?.pathname?.includes(value?.path)
                      ? "flex p-2 text-white"
                      : "flex p-2 text-[#353F4F] hover:text-[#2D53DA]"
                  }
                >
                  <div>{value?.icon}</div>
                  <div className="px-2 my-auto">{value?.name}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full absolute bottom-0 left-0">
        <LogoutButton />
      </div>
    </div>
  );
};

export default Sidebar;
