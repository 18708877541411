import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../Componets/Form";
import { toast } from "react-toastify";
import Header from "../../../Componets/Header";
import { get_all_items } from "../../../Controllers/Items";
import forms from "../../../Data/Forms/Contractors/Contractors/forms";
import headers from "../../../Data/Forms/Contractors/Contractors/headers";
import {
  create_contractor,
  get_contractor,
  update_contractor,
} from "../../../Controllers/Contractors";

const ContractorsForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  let form = forms;
  const header = headers;

  const [value, setValue] = useState(form);

  const formdata = async (data) => {
    if (data?.status) {
      const contractor = id
        ? await update_contractor({ ...data?.data, id: id })
        : await create_contractor(data?.data);
      if (contractor?.status) {
        toast?.success(contractor?.message);
        navigate("/app/contractors");
      } else {
        toast?.error(contractor?.message);
      }
    }
  };

  const fetchData = async () => {
    if (id) {
      const data = {
        id,
      };
      const contractor = await get_contractor(data);
      if (contractor?.status) {
        let options = [];
        contractor?.data?.items?.map((value, index) => {
          options?.push({ value: value?._id, label: value?.name });
        });

        let contractor_data = contractor?.data?.contractor;

        let name = contractor_data?.name ? contractor_data?.name : "";
        let email = contractor_data?.email ? contractor_data?.email : "";
        let phone = contractor_data?.phone ? contractor_data?.phone : "";
        let catlog = contractor_data?.catlog
          ? JSON.parse(contractor_data?.catlog)
          : null;
        let tax = contractor_data?.tax ? contractor_data?.tax : "";
        let area = contractor_data?.area ? contractor_data?.area : "";
        let city = contractor_data?.city ? contractor_data?.city : "";
        let state = contractor_data?.state ? contractor_data?.state : "";
        let country = contractor_data?.country ? contractor_data?.country : "";
        let status = contractor_data?.status ? contractor_data?.status : 0;

        value[0].options[0].form.value = name;

        value[1].options[0].form.value = email;
        value[1].options[1].form.value = phone;

        value[2].options[0].form.value = catlog;
        value[2].options[0].form.options = options;

        value[3].options[0].form.value = tax;

        value[4].options[0].form.value = area;
        value[4].options[1].form.value = city;
        value[4].options[2].form.value = state;
        value[4].options[3].form.value = country;

        value[5].options[0].form.value = status;

        setValue([...value]);
      } else {
        toast?.error(contractor?.message);
      }
    } else {
      const items = await get_all_items();
      if (items?.status) {
        let options = [];
        items?.data?.items?.map((value, index) => {
          options?.push({ value: value?._id, label: value?.name });
        });

        value[2].options[0].form.options = options;

        setValue([...value]);
      } else {
        toast?.error(items?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <Header header={header}>
      <Forms form={value} formdata={formdata} />
    </Header>
  );
};

export default ContractorsForm;
