import {
  LoadingOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Input, Radio, Select, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PurchaseTable from "./table";
import inventory from "../../../Data/inventory.json";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { get_create_purchase } from "../../../Controllers/Purchases";

const AddPurchaseForm = (props) => {
  console.log(props);
  let navigate = useNavigate();
  let location = useLocation();
  const [type, setType] = useState();

  let data = {
    from: "Supplier",
    number: "",
    date: "",
    due_date: null,
    supplier: null,
    supplier_options: [],
    contractor: null,
    contractor_options: [],
    items: [],
    details: [
      {
        item: "",
        units: "",
        price: "",
        quantity: "",
        total: "",
      },
    ],
    total: "",
    tax: "",
    amount: "",
  };

  const [form, setForm] = useState(data);

  const handleType = (value) => {
    form.from = value;
    setForm({ ...form });
  };

  const handleChange = (e) => {
    form[e?.target?.name] = e?.target?.value;
    setForm({ ...form });
  };

  const handleDate = (e, name) => {
    if (name == "date") {
      form.date = e?.$d;
      setForm({ ...form });
    } else if (name == "due_date") {
      form.due_date = e?.$d;
      setForm({ ...form });
    }
  };

  const fetchData = async () => {
    const purchase = await get_create_purchase();
    if (purchase?.status) {
      let suppliers_options = [];
      purchase?.data?.suppliers?.map((value, index) => {
        suppliers_options?.push({ value: value?._id, label: value?.name });
      });

      let contractors_options = [];
      purchase?.data?.contractors?.map((value, index) => {
        contractors_options?.push({ value: value?._id, label: value?.name });
      });

      let items_options = [];
      purchase?.data?.items?.map((value, index) => {
        items_options?.push({ value: value?._id, label: value?.name });
      });

      // form.number = purchase?.data?.number;
      form.supplier_options = suppliers_options;
      form.contractor_options = contractors_options;
      form.items = items_options;
      setForm({ ...form });
    } else {
      toast?.error(purchase?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div class="my-8">
      <div class="bg-white border rounded-md p-8 w-[80%] mx-auto">
        {/* Contractors */}
        <div>
          <div className="text-medium pb-3">From</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <Select
              className="w-full my-3 text-medium"
              placeholder="Select Type"
              options={[
                {
                  value: "Supplier",
                  label: "Supplier",
                },
                { value: "Contractor", label: "Contractor" },
              ]}
              value={form?.from}
              onChange={handleType}
            />
          </div>
        </div>

        {/* detail */}
        <div className="pt-6">
          <div className="flex justify-between pb-5">
            <div className="text-medium  ">Details</div>
            {location?.pathname == "/app/purchases/edit" && (
              <div className="text-medium text-2xl">
                <PrinterOutlined
                  onClick={() => navigate("/app/purchases/view")}
                />
              </div>
            )}
          </div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <div>Number</div>
            <div>
              <Input
                className="w-full my-3 text-medium"
                placeholder="Purchase order number"
                name="number"
                onChange={handleChange}
                value={form?.number}
              />
            </div>
            <div>Date</div>
            <div>
              <DatePicker
                className="w-full my-3 text-medium"
                placeholder="Purchase Date"
                onChange={(e) => handleDate(e, "date")}
                value={form?.date && dayjs(form?.date)}
              />
            </div>
            <div>Due Date</div>
            <div>
              <DatePicker
                className="w-full my-3 text-medium"
                placeholder="Purchase Due Date"
                onChange={(e) => handleDate(e, "due_date")}
                value={form?.due_date && dayjs(form?.due_date)}
              />
            </div>
            <div>Supplier</div>
            <div>
              <Select
                className="w-full my-3 text-medium"
                placeholder={
                  form?.from == "Supplier"
                    ? "Select Supplier"
                    : "Select Contractor"
                }
                allowClear={true}
                showSearch={true}
                filterOption={false}
                options={
                  form?.from == "Supplier"
                    ? form?.supplier_options
                    : form?.contractor_options
                }
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <div className="text-medium  pb-3">Items</div>
          <div className="border text-sm text-medium text-gray-500 rounded-md p-4">
            <PurchaseTable type={type} form={form} setForm={setForm} />
          </div>
        </div>

        {/* submit */}
        <div className="py-6">
          <div className="float-end">
            <Button
              className="mr-2 text-medium"
              onClick={() => navigate("/app/purchases")}
            >
              Cancel
            </Button>
            <Button
              className="text-medium"
              type="primary"
              onClick={() => {
                navigate("/app/purchases");
                toast?.success("Purchase created");
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPurchaseForm;
